import React from 'react';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../Store/index';
import Home from '../Pages/Home';
import Games from '../Pages/Games';

class Header extends React.Component {

    render() {
        return (
          <>
                    <Container className="container-fluid block-example border border-secondary central-area-background-color">
                      <div className="container-fluid">
                        <Navbar collapseOnSelect expand="lg">
                            <Navbar.Brand href="/">
                                <img
                                    src={this.props.companyLogo}
                                    className="d-inline-block align-top logo-img"
                                    alt="Company Logo"
                                />
                            </Navbar.Brand>
                        </Navbar>
                      </div>
                    </Container>
                    <Container className="container-fluid block-example border border-secondary">
                        <div className="container-fluid primary-nav-background-color">
                          <Navbar collapseOnSelect expand="lg">
                              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                              <Navbar.Collapse id="responsive-navbar-nav">
                                  <Nav className="mr-auto">
                                      <Nav.Link className="nav-text-style" href="/home">Home</Nav.Link>
                                      <Nav.Link className="nav-text-style" href="/games">Games</Nav.Link>
                                  </Nav>
                              </Navbar.Collapse>
                          </Navbar>
                        </div>
                        <div className="container-fluid secondary-nav-background-color nav-color-block"></div>
                    </Container>
                    </>
        );
    }
};

const mapStateToProps = state => {
    const companyName = state.general.agentName;
    return {
        companyLogo: state.general[companyName].companyLogo,
        sellableProducts: state.general[companyName].sellableProducts
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSelectCarHireButtonClicked: () => dispatch(actions.changeProduct(100)),
        onSelectExcessButtonClicked: () => dispatch(actions.changeProduct(146)),
        onSelectMotorHomeButtonClicked: () => dispatch(actions.changeProduct(151)),
        onSelectVanHireButtonClicked: () => dispatch(actions.changeProduct(153))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

import React from 'react';

const generalInformation = (props) => (
    <>
        <p className="section-heading-text"><h3>General Information:</h3></p>
        <p>A remake of the classic arcade game asteroids, created in Unity and playable within the browser!</p>
        <p>In terms of gameplay, the game is reasonably faithful to the original arcade versions. The player controls their ship on a static screen, with the ability to wrap around by travelling off the edges of the screen in any direction. Asteroids float around the play space and will kill the player if colliding with them. UFOs also appear from time to time which can be shot down for extra points. The player starts with three lives and will gain extra lives at certain point thresholds.</p>
        <p>The game is played on a 2D playspace using 3D models and features space-themed music and sounds. Whenever the level is cleared the player progresses to the next level, which is essentially the same playspace with extra asteroids to shoot/avoid. The player can continue to progress through levels, increasing their score, until they run out of lives.</p>
    </>
);

export default generalInformation;

//General
export const CHANGE_STEP = 'CHANGE_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const INCREMENT_STEP = 'INCREMENT_STEP';
export const RESET_STEP = 'RESET_STEP';
export const STORE_COUNTRY_LIST = 'STORE_COUNTRY_LIST';
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
export const SELECT_AGENT = 'SELECT_AGENT';
export const TOGGLE_TERMS_AND_CONDITIONS = 'TOGGLE_TERMS_AND_CONDITIONS';

//Dates
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';

//Product
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const STORE_PRODUCT_LIST = 'STORE_PRODUCT_LIST';

//Validation
export const VALIDATE_CREATE_QUOTE_PAGE = 'VALIDATE_CREATE_QUOTE_PAGE';
export const VALIDATE_DRIVERS_AND_EXTRAS_PAGE = 'VALIDATE_DRIVERS_AND_EXTRAS_PAGE';
export const VALIDATE_FULL_QUOTE_PAGE = 'VALIDATE_FULL_QUOTE_PAGE';
export const VALIDATE_DECLARATION_PAGE = 'VALIDATE_DECLARATION_PAGE';
export const VALIDATE_POLICY_HOLDER_PAGE = 'VALIDATE_POLICY_HOLDER_PAGE';
export const VALIDATE_CHECKOUT_PAGE = 'VALIDATE_CHECKOUT_PAGE';

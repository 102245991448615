import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";

class LaserDefenderGame extends React.Component {
  constructor() {
    super();

    // Next up create a new Unity Context object to
    // initialise and define your WebGL build. The
    // paths are relative from your index file.

    this.unityContext = new UnityContext({
      loaderUrl: "/LaserDefender/Build/Laser Defender.loader.js",
      dataUrl: "/LaserDefender/Build/Laser Defender.data",
      frameworkUrl: "/LaserDefender/Build/Laser Defender.framework.js",
      codeUrl: "/LaserDefender/Build/Laser Defender.wasm"
    });
  }

  render() {

    let metaTitle = 'Loftus Games - Laser Defender';
    // Finally render the Unity component and pass
    // the Unity context through the props.

    return (
      <>
        <Unity unityContext={this.unityContext} />
      </>
    );
  }
}

export default LaserDefenderGame;

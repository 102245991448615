import React from 'react';

const features = (props) => (
    <>
        <p className="section-heading-text"><h3>Features:</h3></p>
        <ul>
          <li>Control a spaceship and destroy asteroids without crashing to achieve the highest score possible!</li>
          <li>Unlimited level progression making for a true arcade experience</li>
          <li>Randomised asteroid locations, speed and trajectory to make for a different challenge on every playthrough</li>
          <li>UFOs that can appear and attempt to shoot the player. Shoot these down for bonus points!</li>
          <li>Space-themed music and sound effects</li>
        </ul>
    </>
);

export default features;

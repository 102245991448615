import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import YouTubeIcon from '@material-ui/icons/YouTube';
import RedditIcon from '@material-ui/icons/Reddit';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import { createMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none'
  },
  youtube: {
    color: 'red'
  },
  reddit: {
    color: '#FF4500'
  },
  twitter: {
    color: '#1DA1F2'
  },
  facebook: {
    color: '#0F92F3'
  },
  instagram: {
    color: '#D90053'
  },
  apple: {
    color: 'black'
  },
  android: {
    color: '#AAC148'
  }
}));

export default function SocialLinks() {

  const classes = useStyles();

  return (
    <div>
      <p className="section-heading-text"><h4><b>Social Media & Store Links:</b></h4></p>
      <p><Button className={classes.button} startIcon={<YouTubeIcon className={classes.youtube}/>} href='https://www.youtube.com/channel/UCNsd_n0TwiyMGMeEW5qVDQQ/featured?view_as=subscriber'>Youtube</Button></p>
      <p><Button className={classes.button} startIcon={<RedditIcon className={classes.reddit} />} href='https://www.reddit.com/user/LoftusDev'>Reddit</Button></p>
      <p><Button className={classes.button} startIcon={<TwitterIcon className={classes.twitter} />} href='https://twitter.com/LoftusDev'>Twitter</Button></p>
      <p><Button className={classes.button} startIcon={<FacebookIcon className={classes.facebook} />} href='https://www.facebook.com/LoftusDev'>Facebook</Button></p>
      <p><Button className={classes.button} startIcon={<InstagramIcon className={classes.instagram} />} href='https://www.instagram.com/loftus_dev/'>Instagram</Button></p>
      <p><Button className={classes.button} startIcon={<AndroidIcon className={classes.android} />} href='https://play.google.com/store/apps/developer?id=Loftus+Games'>Android Store</Button></p>
    </div>
  );
}

import React from 'react';

const credits = (props) => (
    <>
        <p className="section-heading-text"><h3>Credits:</h3></p>
        <ul>
          <li><b>Design & Development</b></li>
            <ul>
              <li>Loftus (Rick Taylor)</li>
            </ul>
          <li><b>Original Game Concept & Design</b></li>
            <ul>
              <li>Lyle Rains</li>
              <li>Ed Logg</li>
              <li>Dominic Walsh</li>
              <li>OctoMan</li>
            </ul>
          <li><b>Visuals</b></li>
            <ul>
              <li>OctoMan</li>
              <li>Phil Steinschneider</li>
            </ul>
          <li><b>Audio</b></li>
            <ul>
              <li>Maxstack</li>
              <li>Kenny</li>
            </ul>
        </ul>
    </>
);

export default credits;

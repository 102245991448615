import React from 'react';
import Image from 'react-bootstrap/Image';

const imageGallery = (props) => (
    <>
        <p className="section-heading-text"><h3>Image Gallery:</h3></p>
        <Image src="https://loftus-games.s3.eu-west-2.amazonaws.com/asteroids/asteroids+screenshot+1.jpg" thumbnail />
        <Image src="https://loftus-games.s3.eu-west-2.amazonaws.com/asteroids/asteroids+screenshot+2.jpg" thumbnail />
    </>
);

export default imageGallery;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import {Container, Col, Row } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    marginRight: '10px',
  },
  media: {
    height: 140,
  },
});

export default function GamesDetails() {
  const classes = useStyles();

  return (
    <>
    <p><h2><b>Games Details:</b></h2></p>
    <Container className="container-fluid block-example central-area-background-color">
    <Col>
        <Row>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="https://loftus-games.s3.eu-west-2.amazonaws.com/asteroids/asteroids+screenshot+1.jpg"
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Asteroids
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                A remake of the classic arcade game asteroids, created in Unity and playable within the browser!
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Link to="/games/asteroids"><Button size="small" color="primary">Game Page</Button></Link>
          </CardActions>
        </Card>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="https://loftus-games.s3.eu-west-2.amazonaws.com/laser-defender/start+title.png"
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Laser Defender
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Laser Defender is a vertical shoot em' up set in space, playable on mobile devices.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Link to="/games/laser-defender"><Button size="small" color="primary">Game Page</Button></Link>
          </CardActions>
        </Card>
        </Row>
    </Col>
    </Container>

      <p></p>
    </>
  );
}

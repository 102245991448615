import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";

class AsteroidsGame extends React.Component {
  constructor() {
    super();

    // Next up create a new Unity Context object to
    // initialise and define your WebGL build. The
    // paths are relative from your index file.

    this.unityContext = new UnityContext({
      loaderUrl: "/Asteroids/Build/Asteroids.loader.js",
      dataUrl: "/Asteroids/Build/Asteroids.data",
      frameworkUrl: "/Asteroids/Build/Asteroids.framework.js",
      codeUrl: "/Asteroids/Build/Asteroids.wasm"
    });
  }

  render() {

    let metaTitle = 'Loftus Games - Asteroids';
    // Finally render the Unity component and pass
    // the Unity context through the props.

    return (
      <>
        <Unity width={"100%"} height={"100%"} unityContext={this.unityContext} />
      </>
    );
  }
}

export default AsteroidsGame;

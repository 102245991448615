import React from 'react';

const about = (props) => (
    <>
        <p className="section-heading-text"><h3><b>Welcome to Loftus Games!</b></h3></p>
        <p>Please take a look around and try out some of my games.</p>
    </>
);

export default about;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {Container, Col, Row } from 'react-bootstrap';
import Divider from '@material-ui/core/Divider';

import Header from '../../Containers/Header';
import Footer from '../../Components/Generic/Footer';
import GamesList from '../../Components/GamesList';
import GeneralInformation from '../../Components/Asteroids/GeneralInformation';
import Features from '../../Components/Asteroids/Features';
import Credits from '../../Components/Asteroids/Credits';
import ImageGallery from '../../Components/Asteroids/ImageGallery';
import Play from '../../Components/Asteroids/Play';

class Asteroids extends Component {

  render() {

    let metaTitle = 'Loftus Games - Asteroids';

    return (
      <div className="side-area-background-color">
        <Helmet>
          <title>{metaTitle}</title>
        </Helmet>

        <Header fixed="top" />
        <Container className="block-example border border-secondary central-area-background-color">
        <Row>
            <Col md={8} className="border-right">
              <h1>Asteroids</h1>
              <Divider/>
              <GeneralInformation/>
              <Divider/>
              <Features/>
              <Divider/>
              <ImageGallery/>
              <Divider/>
              <Credits/>
            </Col>
            <Col md={4}>
              <GamesList/>
              <Divider/>
              <Play/>
            </Col>
        </Row>
        </Container>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      productID: state.product.id,
      agentName: state.general.agentName,
      productName: state.product.fullName
  };
}

export default connect(mapStateToProps)(Asteroids);

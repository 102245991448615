import React from 'react';
import Divider from '@material-ui/core/Divider';

export default function LatestUpdates() {

  return (
    <div>
      <p className="section-heading-text"><h2><b>Latest Updates:</b></h2></p>
      <Divider />
      <p><b>November 10th, 2020</b></p>
      <p><h4><b>New Site Live!</b></h4></p>
      <p>After having a placeholder of a website for a few years and not really doing much with it, i've decided to start from scratch and have created the new site to showcase my gaming projects.</p>
      <p>I haven't worked on any websites for over a year so there was definitely a bit of rust, but i'm happy to have it done and more updates will certainly follow in the near future. With this out of the way i can get back to giving full focus to game development!</p>
      <p>Please take a look around the site and check out the games i have available. Social media links can be used to follow my development journey and store links provide quick access to my games that are available on various storefronts.</p>
      <p>Here's to what the future brings!</p>
    </div>
  );
}

import * as actionTypes from '../ActionTypes';
import { updateObject } from '../Utility';
import React from 'react';

const initialState = {
    advertiserId: 0,
    agentList: {
        100140: 'Confused',
        100435: 'Questor',
        115437: 'InsureMyExcess',
        448090: 'GetCover',
        673750: 'Channel',
        686098: 'CheapExcess',
        689061: 'AffordableRentalCover'
    },
    agentId: 100435,
    agentName: 'Questor',
    alternativeContacts: 'placeholder, should be multiple available',
    Questor: {
        companyName: 'Questor Insurance Services Limited',
        companyLogo: 'https://loftus-games.s3.eu-west-2.amazonaws.com/Logo+No+Background+-+282+x+145.png',
        companyAddress: 'Third Floor Riverside House, 40-46 High Street, Maidstone, Kent, ME14 1JH, United Kingdom',
        companySmallText: 'Authorised and Regulated by the Financial Conduct Authority',
        sellableProducts: [100, 146, 151, 153],
    },
    GetCover: {
        companyName: 'getcover.ie',
        companyLogo: 'https://loftus-games.s3.eu-west-2.amazonaws.com/Logo+No+Background+-+282+x+145.png',
        companyAddress: '6, Leopardstown Office Park, Sandyford, Dublin 18',
        companySmallText: 'Getcover & Co. Ltd T/A Getcover.ie is regulated by the Central Bank of Ireland. Registered in Ireland No 309229',
        sellableProducts: [100],
    },
    Channel: {
        companyName: 'Channel Insurance Brokers Limited',
        companyLogo: 'https://questor-cms.s3.amazonaws.com/QuestorAffiliatesImages/QuestorLogo.png',
        companyAddress: '4 South Esplanade, St Peter Port, Guernsey, Channel Islands, GY1 3PZ',
        companySmallText: 'CIB is a trading style of Channel Insurance Brokers Limited, which is licensed by the Guernsey Financial Services Commission for Insurance Business and is regulated by the Jersey Financial Services Commission for General Insurance Mediation Business, GFSC Ref 2050. Company Registration Number 8722.',
        sellableProducts: [100, 146],
    },
    country: '',
    countryList: [],
    currency: '£',
    currencyId: 826,
    discountId: 0,
    drivers: 1,
    expiryGuid: '',
    isLoading: false,
    language: 'en',
    paymentSuccess: false,
    sessionId: 0,
    step: 1,
    vehicles: 1,
    visitorId: 0,
    termsAndConditionsChecked: false
}

/// Reducers ///

const selectAgent = (state, action) => {

    const updatedState = {...state};

    let updatedAgentName = updatedState.agentId;
    const agents = Object.keys(updatedState.agentList);

    for (let i = 0; i < agents.length; i++) {
        if(agents[i] == action.agentId) {
            updatedAgentName = updatedState.agentList[action.agentId];
        }
    }

    return updateObject(state, {agentId: action.agentId, agentName: updatedAgentName});
}

const changeStep = (state, action) => {
    window.scrollTo(0, 0);
    return updateObject(state, {step: action.step});
}

const decrementStep = (state, action) => {
    window.scrollTo(0, 0);
    return updateObject(state, { step: state.step - 1 });
}

const incrementStep = (state, action) => {
    window.scrollTo(0, 0);
    return updateObject(state, { step: state.step + 1 });
}

const resetStep = (state, action) => {
    window.scrollTo(0, 0);
    return updateObject(state, { step: 1, paymentSuccess: false });
}

const storeCountryList = (state, action) => {

    const countryList = [];

    countryList.push(<option key='1082' value='1082' defaultValue='1082' >United Kingdom</option>);
    countryList.push(action.countries);

    return updateObject(state, { countryList: countryList });
};

const updateLoadingStatus = (state, action) => {
    const updatedState = {...state};
    updatedState.isLoading = action.isLoading;
    return updateObject(state, {isLoading: updatedState.isLoading});
}

const toggleTermsAndConditions = (state, action) => {
    const updatedState = {...state};
    updatedState.termsAndConditionsChecked = !state.termsAndConditionsChecked
    return updateObject(state, {termsAndConditionsChecked: updatedState.termsAndConditionsChecked});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_AGENT: return selectAgent(state, action);
        case actionTypes.CHANGE_STEP: return changeStep(state, action);
        case actionTypes.DECREMENT_STEP: return decrementStep(state, action);
        case actionTypes.INCREMENT_STEP: return incrementStep(state, action);
        case actionTypes.RESET_STEP: return resetStep(state, action);
        case actionTypes.STORE_COUNTRY_LIST: return storeCountryList(state, action);
        case actionTypes.UPDATE_LOADING_STATUS: return updateLoadingStatus(state, action);
        case actionTypes.TOGGLE_TERMS_AND_CONDITIONS: return toggleTermsAndConditions(state, action);
        default:
            return state;
    }
};

export default reducer;

import React from 'react';

const gamesAbout = (props) => (
    <>
        <p className="section-heading-text"><h2><b>Games:</b></h2></p>
        <p>Here you will find a list of all the games i have created. Quick access can be done via the list on the side, while more detailed info can be seen below.</p>
    </>
);

export default gamesAbout;

import * as actionTypes from '../ActionTypes';
import { updateObject } from '../Utility';

const initialState = {
    currentYear: new Date().getFullYear(),
    startDate: new Date(),
    endDate: new Date()
}

const setStartDate = (state, action) => {
    return updateObject(state, state);
}

const setEndDate = (state, action) => {
    return updateObject(state, state);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_START_DATE: return setStartDate(state, action);
        case actionTypes.SET_END_DATE: return setEndDate(state, action);
        default:
            return state;
    }
};

export default reducer;
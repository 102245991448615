import React from 'react';

const features = (props) => (
    <>
        <p className="section-heading-text"><h3>Features:</h3></p>
        <ul>
          <li>Control a spaceship and fight off enemy spaceships to protect earth!</li>
          <li>Four levels for the player to progress through in order to complete the game</li>
          <li>Enemy path randomization, making it so that each playthrough of the game differs from the last</li>
          <li>Four different enemy types, with four variations of each, plus a unique final boss enemy</li>
          <li>Five pieces of music that are played throughout the game</li>
          <li>Three difficulty settings, 'easy', 'normal' and 'hard'</li>
        </ul>
    </>
);

export default features;

import * as actionTypes from '../ActionTypes';
import { updateObject } from '../Utility';

const initialState = {
    id: 0,
    fullName: '',
    shortName: '',
    productList: [],
    subProductId: 207,
    subProductName: '' ,
    productInformation: {
        optionalExtras: {
            dualLeadDriverTitle: '',
            dualLeadDriverDescription: '',
            extendedRentalCoverTitle: '',
            extendedRentalCoverDescription: '',
            batteryFailureCoverTitle: '',
            batteryFailureCoverDescription: '',
            collisionDamageWaiverTitle: '',
            collisionDamageWaiverDescription: '',
            noOptionalExtrasText: ''
        },
        policyDocumentLink: '',
        dailyProductDescription: '',
        annualProductDescription: '',
        ukTerritorialLimits: 'UK means England, Scotland, Wales, Northern Ireland, Channel Islands and the Isle of Man',
        europeanTerritorialLimits: 'Europe means countries which are members of the EEA: Austria, Belgium, Bulgaria, Cyprus, Croatia, Czech Republic, Denmark, Estonia, Finland, France, Germany, Gibraltar, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden and the United Kingdom. And countries to the west of the Ural Mountains, including, islands in the Mediterranean, Morocco, Tunisia, Turkey, Canary Islands, Madeira, and the Azores.',
        worldwideTerritorialLimits: 'Worldwide means anywhere in the world apart from Afghanistan, Belarus, Cuba, Conga, Iran, Iraq, Ivory Coast, Liberia, Myanmar, North Korea, South Sudan, Sudan, Syria and Zimbabwe. No cover is provided for claims arising as a direct result of a situation highlighted by the Foreign and Commonwealth Office where you have hired a vehicle in a specific country or area where, prior to the vehicle hire agreement commencing, the Foreign and Commonwealth Office has advised against all (but essential) travel.',
        importantInformation: [],
    }
}

const selectProduct = (state, action) => {

    const updatedState = {...state};
    updatedState.productInformation.importantInformation = [];

    switch (action.productId) {
        case 100:
            updatedState.id = 100;
            updatedState.fullName = 'Car Hire Excess Insurance';
            updatedState.shortName = 'Car Hire';
            updatedState.productInformation.optionalExtras.dualLeadDriverTitle = 'Dual Lead Driver';
            updatedState.productInformation.optionalExtras.dualLeadDriverDescription = '- Allows two persons, who must be close relatives, named on the certificate of insurance as lead drivers to rent cars independently when you purchase an annual policy.';
            updatedState.productInformation.optionalExtras.extendedRentalCoverTitle = 'Extended Rental Cover';
            updatedState.productInformation.optionalExtras.extendedRentalCoverDescription = '- For annual policies extends the maximum trip length to 62 days.';
            updatedState.productInformation.optionalExtras.batteryFailureCoverTitle = 'Battery Failure Cover';
            updatedState.productInformation.optionalExtras.batteryFailureCoverDescription = '- Covers you should the battery on your hire vehicle fails.';
            updatedState.productInformation.optionalExtras.collisionDamageWaiverTitle = 'Collision Damage Waiver';
            updatedState.productInformation.optionalExtras.collisionDamageWaiverDescription = '- Increases cover under Excess Reimbursement sections to £75,000';
            updatedState.productInformation.policyDocumentLink = 'https://questor-cms.s3.amazonaws.com/KeyDocuments/AffiliatePolicyDocuments/10282.pdf';
            updatedState.productInformation.dailyProductDescription = 'Daily - Insures a single car rental agreement up to 180 days in length.';
            updatedState.productInformation.annualProductDescription = 'Annual - Covers unlimited rental agreements in the policy year up to 31 days in length. This can be extended to 62 days.';
            updatedState.productInformation.importantInformation.push('This insurance excludes rentals that have already commenced');
            updatedState.productInformation.importantInformation.push('The contract of insurance is subject to UK jurisdiction');
            updatedState.productInformation.importantInformation.push('The policy document is only available in English');
            updatedState.productInformation.importantInformation.push('All policies are liable for Insurance Premium Tax chargeable in the Country where you are permanently resident.');
            break;
        case 146:
            updatedState.id = 146;
            updatedState.subProductId = 101;
            updatedState.fullName = 'Private Motor Excess Insurance';
            updatedState.shortName = 'Motor Excess Protection';
            updatedState.productInformation.policyDocumentLink = 'https://policydocuments.s3.amazonaws.com/146/GB/MotorExcessPolicyDocument.pdf';
            break;
        case 151:
            updatedState.id = 151;
            updatedState.fullName = 'Motorhome Hire Insurance';
            updatedState.shortName = 'Motorhome Hire';
            updatedState.productInformation.optionalExtras.noOptionalExtrasText = 'There are no optional extras with this policy.';
            updatedState.productInformation.policyDocumentLink = 'https://questor-cms.s3.amazonaws.com/KeyDocuments/AffiliatePolicyDocuments/10292.pdf';
            updatedState.productInformation.dailyProductDescription = 'Daily - Insures a single motorhome rental agreement up to 45 days in length.';
            updatedState.productInformation.importantInformation.push('You will be liable for the first £350 or any loss')
            updatedState.productInformation.importantInformation.push('Covers vehicles up to 7.5 Tonnes with a value up to £75,000 at the hire agreement’s start date which are not more than 20 years old from first registration.')
            updatedState.productInformation.importantInformation.push('This insurance excludes rentals that have already commenced')
            updatedState.productInformation.importantInformation.push('The contract of insurance is subject to UK jurisdiction')
            updatedState.productInformation.importantInformation.push('The policy document is only available in English')
            updatedState.productInformation.importantInformation.push('All policies are liable for Insurance Premium Tax based on where you are resident.')
            break;
        case 153:
            updatedState.id = 153;
            updatedState.fullName = 'Van Hire Insurance';
            updatedState.shortName = 'Van Hire';
            updatedState.productInformation.optionalExtras.noOptionalExtrasText = 'There are no optional extras with this policy.';
            updatedState.productInformation.policyDocumentLink = 'https://questor-cms.s3.amazonaws.com/KeyDocuments/AffiliatePolicyDocuments/10312.pdf';
            updatedState.productInformation.dailyProductDescription = 'Daily - Insures a single van rental agreement up to 14 days in length.';
            updatedState.productInformation.importantInformation.push('You will be liable for the first £200 of any loss')
            updatedState.productInformation.importantInformation.push('Covers vehicles up to 7.5 Tonnes with a value up to £75,000 at the hire agreement’s start date which are not more than 20 years old from first registration.')
            updatedState.productInformation.importantInformation.push('This insurance excludes rentals that have already commenced')
            updatedState.productInformation.importantInformation.push('The contract of insurance is subject to UK jurisdiction')
            updatedState.productInformation.importantInformation.push('The policy document is only available in English')
            updatedState.productInformation.importantInformation.push('All policies are liable for Insurance Premium Tax based on where you are resident.')
            break;
        default:
            updatedState.id = 0;
            updatedState.fullName = '';
            updatedState.shortName = '';
    }

    return updateObject(state, updatedState);
};

const storeProductList = (state, action) => {
    return updateObject(state, { productList: action.products });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_PRODUCT: return selectProduct(state, action);
        case actionTypes.STORE_PRODUCT_LIST: return storeProductList(state, action);
        default:
            return state;
    }
};

export default reducer;
import Validator from 'validatorjs';
import * as actionTypes from '../ActionTypes';
import { updateObject } from '../Utility';

const initialState = {
    validationSuccess: true,
    validationErrors: []
}

const validateCreateQuotePage = (state, action) => {

    let validationSuccess;
    const validationErrors = [];

    let data = action.validationObject;
    
    if(action.productId === 100 | action.productId === 151 | action.productId === 153) {
        let rules = {
            subProductId: 'min:1',
            territoryId: 'min:1',
            numberOfDrivers: 'min:1'
        };
           
        let validation = new Validator(data, rules);
    
        if (validation.passes()) {
            validationSuccess = true;
        } else {
            validationSuccess = false;
            window.scrollTo(0, 0);
        }
    
        if (validation.errors.has('subProductId')) {
            validationErrors.push(`Please select a product.`);
        }
        if (validation.errors.has('territoryId')) {
            validationErrors.push(`Please select a territory.`);
        }
        if (validation.errors.has('numberOfDrivers')) {
            validationErrors.push(`Please select the number of drivers you wish to cover.`);
        }
    
        
        return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
    } else if (action.productId === 146) {

        let rules = {
            regNumber: 'required'
        };

        let validation = new Validator(data, rules);

        if (validation.passes()) {
            validationSuccess = true;
        } else {
            validationSuccess = false;
            window.scrollTo(0, 0);
        }

        if (validation.errors.has('regNumber')) {
            validationErrors.push(`Please enter a vehicle registration.`);
        }

        
        return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
    } else {

        let rules = {};

        let validation = new Validator(data, rules);

        if (validation.passes()) {
            validationSuccess = true;
        } else {
            validationSuccess = false;
            window.scrollTo(0, 0);
        }

        
        return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
    }
}

const validateDriversAndExtrasPage = (state, action) => {


    let validationSuccess;
    const validationErrors = [];

    let data = action.validationObject;

    let rules = {};

    for(let i = 1; i <= action.numberOfDrivers; i++) {
        rules[`driverName${i}`] = 'required';
        rules[`driverAge${i}`] = 'required';
    }

    let validation = new Validator(data, rules);

    if (validation.passes()) {
        validationSuccess = true;
    } else {
        validationSuccess = false;
        validationErrors.push(`Please enter a name and age for all drivers.`);
        window.scrollTo(0, 0);
    }

    
    return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
}

const validateFullQuotePage = (state, action) => {

    let validationSuccess;
    const validationErrors = [];

    let data = action.validationObject;

    if(action.productId === 100 | action.productId === 151 | action.productId === 153) {

        let rules = {
            termsAndConditionsChecked: 'accepted'
        };
           
        let validation = new Validator(data, rules);
    
        if (validation.passes()) {
            validationSuccess = true;
        } else {
            validationSuccess = false;
        }
    
        if (validation.errors.has('termsAndConditionsChecked')) {
            validationErrors.push(`Please accept the terms and conditions in order to proceed.`);
            window.scrollTo(0, 0);
        }
    
        
        return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
    } else if (action.productId === 146) {

        let rules = {};

        let validation = new Validator(data, rules);

        if (validation.passes()) {
            validationSuccess = true;
        } else {
            validationSuccess = false;
            window.scrollTo(0, 0);
        }

        
        return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
    } else {

        let rules = {};

        let validation = new Validator(data, rules);

        if (validation.passes()) {
            validationSuccess = true;
        } else {
            validationSuccess = false;
            window.scrollTo(0, 0);
        }

        
        return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
    }
}

const validateDeclarationPage = (state, action) => {

    let validationSuccess;
    const validationErrors = [];

    let data = action.validationObject;

    let rules = {
        termsAndConditionsChecked: 'accepted'
    };
        
    let validation = new Validator(data, rules);

    if (validation.passes()) {
        validationSuccess = true;
    } else {
        validationSuccess = false;
    }

    if (validation.errors.has('termsAndConditionsChecked')) {
        validationErrors.push(`Please accept the terms and conditions in order to proceed.`);
        window.scrollTo(0, 0);
    }

    
    return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
}

const validatePolicyHolderPage = (state, action) => {

    let validationSuccess;
    const validationErrors = [];

    let data = action.validationObject;

    let rules = {};
        
    let validation = new Validator(data, rules);

    if (validation.passes()) {
        validationSuccess = true;
    } else {
        validationSuccess = false;
    }

    if (validation.errors.has('')) {
        validationErrors.push('do something');
        window.scrollTo(0, 0);
    }
    
    return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
}

const validateCheckoutPage = (state, action) => {

    let validationSuccess;
    const validationErrors = [];

    let data = action.validationObject;

    let rules = {};
        
    let validation = new Validator(data, rules);

    if (validation.passes()) {
        validationSuccess = true;
    } else {
        validationSuccess = false;
    }

    if (validation.errors.has('')) {
        validationErrors.push('do something');
        window.scrollTo(0, 0);
    }
    
    return updateObject(state, {validationSuccess: validationSuccess, validationErrors: validationErrors});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VALIDATE_CREATE_QUOTE_PAGE: return validateCreateQuotePage(state, action);
        case actionTypes.VALIDATE_DRIVERS_AND_EXTRAS_PAGE: return validateDriversAndExtrasPage(state, action);
        case actionTypes.VALIDATE_FULL_QUOTE_PAGE: return validateFullQuotePage(state, action);
        case actionTypes.VALIDATE_DECLARATION_PAGE: return validateDeclarationPage(state, action);
        case actionTypes.VALIDATE_POLICY_HOLDER_PAGE: return validatePolicyHolderPage(state, action);
        case actionTypes.VALIDATE_CHECKOUT_PAGE: return validateCheckoutPage(state, action);
        default:
            return state;
    }
};

export default reducer;
import React from 'react';
import * as actionTypes from '../ActionTypes';
import * as actions from '../index';
import axios from '../../axios';

export const selectProduct = (productId) => {
    return {
        type: actionTypes.SELECT_PRODUCT,
        productId: productId
    }
};

//async
export const changeProduct = (productId) => {
    return dispatch => {
        dispatch(selectProduct(productId));
        dispatch(actions.resetStep());
    };
};

//async
export const getProductList = (agentId, productId) => {

    return dispatch => {
        const apiAddress = `Quote/GetProductsByAgentId/${agentId}/${productId}`;
        axios.get(apiAddress)
        .then(response => {
            const productArray = [];
            productArray.push(<option key='0' value='0' defaultValue='0' disabled={false}>Select A Product</option>);

            for (var i = 0; i < response.data.length; i++) {
                productArray.push(<option key={response.data[i].productId} id='productOptions' value={response.data[i].productId}>{response.data[i].productDesc}</option>);
            }

            dispatch(storeProductList(productArray));
        });
    };
};

export const storeProductList = (productArray) => {

    return {
        type: actionTypes.STORE_PRODUCT_LIST,
        products: productArray
    };
}
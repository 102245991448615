import React from 'react';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

class GamesList extends React.Component {

    render() {
        return (
          <div>
            <p className="section-heading-text"><h3><b>Games List:</b></h3></p>
            <Divider/>
            <p className="section-heading-text"><Link className="nav-text-style" to="/games/asteroids">Asteroids</Link></p>
            <p><Link className="nav-text-style" to="/games/laser-defender">Laser Defender</Link></p>
          </div>
        );
    }
};

export default withRouter(GamesList);

import React, { Component } from 'react';
import { connect } from 'react-redux';

class CustomCss extends Component {

  render() {

    let customCss= process.env.PUBLIC_URL + '/css/Site.css';

    return (
      <link rel="stylesheet" type="text/css" href={customCss} />
    );
  }
}

export default CustomCss;

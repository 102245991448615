import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {Container, Col, Row } from 'react-bootstrap';
import Divider from '@material-ui/core/Divider';

import Header from '../Containers/Header';
import Footer from '../Components/Generic/Footer';
import About from '../Components/About';
import SocialLinks from '../Components/SocialLinks';
import LatestUpdates from '../Components/LatestUpdates';

class Home extends Component {

  render() {

    let metaTitle = 'Loftus Games - Home';

    return (
      <div className="side-area-background-color">
        <Helmet>
          <title>{metaTitle}</title>
        </Helmet>
        <Header fixed="top" />
        <Container className="block-example border border-secondary central-area-background-color">
        <Row>
            <Col md={8}>
              <LatestUpdates />
            </Col>
            <Col md={4}>
              <About/>
              <Divider/>
              <SocialLinks/>
            </Col>
        </Row>
        </Container>
        <Footer/>
      </div>
    );
  }
}

export default Home;

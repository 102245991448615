import React from 'react';

const generalInformation = (props) => (
    <>
        <p className="section-heading-text"><h3>General Information:</h3></p>
        <p>Laser Defender is a vertical shoot em' up set in space, created in Unity and predominantly designed for play on mobile devices.</p>
        <p>To win, the player must shoot down enemy spacecraft while avoiding projectiles. Successfully outlasting all enemy waves in a level will proceed to the next level, while taking too much damage will result in the player ship being destroyed and a game over.</p>
    </>
);

export default generalInformation;

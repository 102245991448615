import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//reducers
import contactPreferencesReducer from './Store/Reducers/ContactPreferences';
import datesReducer from './Store/Reducers/Dates';
import generalReducer from './Store/Reducers/General';
import productReducer from './Store/Reducers/Product';
import validationReducer from './Store/Reducers/Validation';

const rootReducer = combineReducers({
    contactPreferences: contactPreferencesReducer,
    dates: datesReducer,
    general: generalReducer,
    product: productReducer,
    validation: validationReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const app = (
    <Provider store={store}>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

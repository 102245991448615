import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AndroidIcon from '@material-ui/icons/Android';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none'
  },
  apple: {
    color: 'black'
  },
  android: {
    color: '#AAC148'
  }
}));

const Play = () => {

    const classes = useStyles();
      return (
          <>
              <p className="section-heading-text"><h3>Play:</h3></p>
              <p>The game is available on mobile for both Android and iOS:</p>
              <p><Button className={classes.button} startIcon={<AndroidIcon className={classes.android} />} href='https://play.google.com/store/apps/details?id=com.LoftusRT.LaserDefender'>Android Store</Button></p>

              <p><b>Controls:</b></p>
              <ul>
                <li>Movement - WASD or Arrow Keys</li>
                <li>Shooting - Left Mouse Button </li>
              </ul>
          </>
      );
};

export default withRouter(Play);

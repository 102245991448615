import React from 'react';
import * as actionTypes from '../ActionTypes';
import axios from '../../axios';

export const selectAgent = (agentId) => {
    return {
        type: actionTypes.SELECT_AGENT,
        agentId: agentId
    }
}

export const changeStep = (step) => {
    return {
        type: actionTypes.CHANGE_STEP,
        step: step
    }
}

export const decrementStep = () => {
    return {
        type: actionTypes.DECREMENT_STEP
    }
};

export const incrementStep = () => {
    return {
        type: actionTypes.INCREMENT_STEP
    }
};

export const resetStep = () => {
    return {
        type: actionTypes.RESET_STEP
    }
};

//async
export const getCountryList = (productId) => {

    return dispatch => {
        const apiAddress = 'Quote/GetCountry/' + productId;
        axios.get(apiAddress, productId)
        .then(response => {
            const countriesArray = [];
            for (var i = 0; i < response.data.length; i++) {
                if (parseInt(response.data[i].countryID) !== 1082) {
                    countriesArray.push(<option key={response.data[i].countryID} value={response.data[i].countryID}>{response.data[i].countryDescription}</option>);
                }
            }
            dispatch(storeCountryList(countriesArray));
        });
    };
};

export const storeCountryList = (countriesArray) => {

    return {
        type: actionTypes.STORE_COUNTRY_LIST,
        countries: countriesArray
    };
}

export const updateLoadingStatus = (isLoading) => {
    return {
        type: actionTypes.UPDATE_LOADING_STATUS,
        isLoading: isLoading
    }
}

export const toggleTermsAndConditions = () => {
    return {
        type: actionTypes.TOGGLE_TERMS_AND_CONDITIONS
    }
}
import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#0AF7F1',
    color: '#2D51A3'
  }
}));

const Play = () => {

    const classes = useStyles();
      return (
          <>
              <p className="section-heading-text"><h3>Play:</h3></p>
              <p>The game can be played by clicking here: <Button variant="contained" className={classes.button} href="/games/asteroids/play">Play</Button></p>

              <p><b>Controls:</b></p>
              <ul>
                <li>Movement - WASD or Arrow Keys</li>
                <li>Shooting - Spacebar</li>
              </ul>

              <p>*Due to the control scheme used in the game, Asteroids is only playabale on desktop devices that feature keyboards.</p>
          </>
      );
};

export default withRouter(Play);

import React from 'react';

const credits = (props) => (
    <>
        <p className="section-heading-text"><h3>Credits:</h3></p>
        <ul>
          <li><b>Design & Development</b></li>
            <ul>
              <li>Loftus (Rick Taylor)</li>
            </ul>
          <li><b>Original Game Concept & Design</b></li>
            <ul>
              <li>Rick Davidson</li>
            </ul>
          <li><b>Visuals</b></li>
            <ul>
              <li>Kenney</li>
            </ul>
          <li><b>Audio</b></li>
            <ul>
              <li>SketchyLogic</li>
              <li>Kenny</li>
            </ul>
        </ul>
    </>
);

export default credits;

//import * as actionTypes from '../ActionTypes';

const initialState = {
    emailPreference: false,
    mobilePreference: false,
    postPreference: false,
    smsPreference: false,
    telephonePreference: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
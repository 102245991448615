import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {Container, Col, Row } from 'react-bootstrap';
import Divider from '@material-ui/core/Divider';

import Header from '../Containers/Header';
import Footer from '../Components/Generic/Footer';

import GamesAbout from '../Components/GamesAbout';
import GamesDetails from '../Components/GamesDetails';
import GamesList from '../Components/GamesList';

class Games extends Component {

  render() {

    let metaTitle = 'Loftus Games - Games';

    return (
      <div className="side-area-background-color">
        <Helmet>
          <title>{metaTitle}</title>
        </Helmet>
        <Header fixed="top" />
        <Container className="block-example border border-secondary central-area-background-color">
        <Row>
            <Col md={8} className="border-right">
              <GamesAbout />
              <Divider />
              <p></p>
              <GamesDetails />
            </Col>
            <Col md={4}>
              <GamesList/>
            </Col>
        </Row>
        </Container>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      productID: state.product.id,
      agentName: state.general.agentName,
      productName: state.product.fullName
  };
}

export default connect(mapStateToProps)(Games);

import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, withRouter, Link, Redirect } from 'react-router-dom'
import './App.css';
import CustomCss from './customCss';
import Home from './Pages/Home';
import Games from './Pages/Games';
import Asteroids from './Pages/Games/Asteroids';
import AsteroidsGame from './Games/Asteroids/AsteroidsGame';
import LaserDefender from './Pages/Games/LaserDefender';
import LaserDefenderGame from './Games/LaserDefender/LaserDefenderGame';
import { connect } from 'react-redux';
import * as actions from './Store/index';

class App extends Component {

  render() {

    let routes = (
        <Switch>
          <Route path ="/home" exact component={Home} />
          <Route path ="/games" exact component={Games} />
          <Route path ="/games/asteroids" exact component={Asteroids} />
          <Route path ="/games/asteroids/play" exact component={AsteroidsGame} />
          <Route path="/games/laser-defender" exact component={LaserDefender} />
          <Route path ="/games/laser-defender/play" exact component={LaserDefenderGame} />
          <Route path="/" exact component={Home} />
          <Redirect to="/" />
        </Switch>
    );

    return (
        <div>
          <CustomCss />
            {routes}
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectAgent: (agentId) => dispatch(actions.selectAgent(agentId)),
    selectProduct: (productId) => dispatch(actions.selectProduct(productId))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App));

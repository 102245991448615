import React, { Component } from 'react';
import {Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../Store/index';

class Footer extends Component {

    render() {
        return(
          <Container className="container-fluid block-example border border-secondary central-area-background-color">
            <footer>
                <div className="secondary-nav-background-color footer-color-block"></div>
                <div className="container footer-copyright text-center py-3">
                    <p><b>© {this.props.currentYear} Copyright: Loftus Games</b></p>
                </div>
            </footer>
        </Container>
        );
    }
}

const mapStateToProps = state => {
    const companyName = state.general.agentName;
    return {
        currentYear: state.dates.currentYear
    };
}

export default connect(mapStateToProps)(Footer);
